<template>
	<footer>
		<div class="footer-element">
			<div class="row">
				<div class="columns column3 footer-logos">
					<img class="footer-logo" loading="lazy" src="/images/logo-city-hotel-gouda-full.png" />
					<a href="https://www.worldhotels.com/" target="_blank" rel="noopener noreferrer">
						<img class="footer-logo-member" loading="lazy" src="/images/footer-crafted-by.png" />
					</a>
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerContact') }}</h3>
					<p class="contact-info">
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerLocation') }}</h3>
					<p>
						<span>{{ defaults[locale].website.hotelName }}</span
						><br />
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span
						><br />
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span
						><br />
						<span>{{ defaults[locale].website.country }}</span
						><br />
					</p>
				</div>
				<div class="columns column3">
					<h3>{{ $t('footerNavigate') }}</h3>
					<p class="footer-nav">
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }} <br />
						</nuxt-link>
						<span v-if="defaults[locale].cookieConsent.published">
							<a href="#" @click.prevent="openConsent">{{ $t('consentOpen') }}</a>
						</span>
					</p>
				</div>
			</div>
			<div class="row">
				<div class="columns column12">
					<hr />
				</div>
			</div>
		</div>
		<div class="footer-element author">
			<div class="row">
				<div class="columns column12 align-center">
					<p class="align-left">
						<span v-for="(item, idx) in bottomfooter[locale]" :key="item.filename">
							<nuxt-link :to="item.filename">
								{{ item.header }}
							</nuxt-link>
							<span v-if="idx < bottomfooter[locale].length - 1"> | </span>
						</span>
					</p>
					<p class="align-right">
						<a :href="$t('siteByUrl')" target="_blank" rel="noopener noreferrer"> Hotel Website Design </a>
						by:
						<a :href="$t('siteByUrl')" target="_blank">
							<img class="siteByLogo" :src="$t('siteByLogo')" loading="lazy" alt="Site by" />
							Porter
						</a>
					</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu, bottomfooter } = footer.value;
const { locale } = useI18n();
const { openConsent } = useCookieConsent();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footer-element {
	background: #161616;
	color: #f4f0e7;
	padding: 75px 0 10px;

	hr {
		background: #f4f0e7;
	}

	a {
		color: #f4f0e7;

		&:hover {
			color: var(--cta-color);
		}
	}

	h3 {
		color: #fff;
		font-size: var(--h4-font-size);
	}

	.column3 {
		width: 23%;

		&:first-child {
			width: calc(31% - 30px);
		}
	}
}

.footer-logos {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	padding: 0 50px 0 0;
	border-right: 1px solid #fff;
	width: calc((100% / 12 * 3) - 30px);
	margin: 0 30px 0 0;

	> * {
		min-width: 0;
	}

	img {
		max-width: 100%;
	}
}

.footer-logo {
	width: 110px;
}

.footer-logo-member {
	width: 150px;
}

.footer-element.author {
	padding: 10px 0;
	font-size: 12px;
}

.contact-info {
	a {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		gap: 10px;
	}
}

.author {
	.column12 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

.socials {
	margin-bottom: 25px;
}

.socials a {
	color: #fff;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 22px;
	margin: 5px 5px 5px 0;
	text-decoration: none;
}

.socials a:hover {
	color: #fff;
	background: var(--cta-color-hover);
}

@media (max-width: 1080px) {
	.footer-element {
		.column3 {
			a {
				word-break: break-all;
			}
		}
	}
}

@media (max-width: 920px) {
	.footer-element {
		.column3 {
			width: 50%;

			a {
				word-break: normal;
			}

			&.footer-logos {
				width: 100%;
				text-align: center;
				justify-content: center;
				gap: 25px;
				border-right: none;
				border-bottom: 1px solid #fff;
				padding: 0 0 50px;
				margin: 0 auto 25px;
			}
		}
	}

	.author .columns {
		margin: 0;
		flex-flow: wrap column-reverse;
	}

	.author p {
		width: 100%;
		margin: 0 0 5px;
		text-align: center;
	}
}

@media (max-width: 680px) {
	.footer-element {
		text-align: center;

		.column3 {
			width: 100%;
		}
	}

	.contact-info a {
		justify-content: center;
	}
}
</style>
